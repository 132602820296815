import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useToast } from '@mezzoforte/forge';
import { formatCurrency } from '@/util/currency';
import { priceWithVat } from '@/util/price';
import type { FavoriteList } from '@/types/Favorite';
import type { FavoritesAPIResponse, SmsNotificationFailureAPIResponse } from '@/types/ApiResponse';
import { useApi } from '@/hooks/useApi';

export const useFavorites = () => {
  const { apiClient } = useApi();

  const fetchFavorites = async (): Promise<FavoriteList> =>
    apiClient.get<FavoritesAPIResponse>('/api/favorites').then(({ data }) =>
      // Map API Response object to FavoriteList interface
      Promise.resolve({
        success: data.success,
        bidderIds: new Map(Object.entries(data.bidderIds ?? {}).map(([a, b]) => [Number(a), b])),
        entries: data.entries.map((e) => {
          const totalPriceAmount = e.bidCount
            ? priceWithVat(e.highestBid, e.vatPerc)
            : priceWithVat(e.startPrice, e.vatPerc);
          return {
            ...e,
            auctionStart: new Date(e.auctionStart),
            auctionEnd: new Date(e.auctionEnd),
            totalPrice: { amount: totalPriceAmount, vatPercent: e.vatPerc },
            totalPriceString: formatCurrency(totalPriceAmount),
          };
        }),
        entriesWithNotifications: new Set(data.entriesWithNotifications),
      })
    );

  const queryClient = useQueryClient();
  const favorites = useQuery({ queryKey: ['entries', 'favorites'], queryFn: fetchFavorites, staleTime: 1000 * 60 });
  const { playToast } = useToast();

  const addFavorite = useMutation({
    mutationFn: (entryId: number) =>
      apiClient.post('/api/favorites', { entryId }).catch((reason: AxiosError) => {
        if (reason.response?.status === 404) return Promise.resolve({ success: true });
        return Promise.reject(reason);
      }),
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['entries', 'favorites'] });
      void queryClient.invalidateQueries({ queryKey: ['favoriteCount'] });
    },
  });
  const removeFavorite = useMutation({
    mutationFn: (id: number) => apiClient.delete(`/api/favorites/${id}`),
    onMutate: async (id) => {
      // Optimistic update
      await queryClient.cancelQueries({ queryKey: ['entries', 'favorites'] });

      const previousFavorites = queryClient.getQueryData<FavoriteList>(['entries', 'favorites']);
      queryClient.setQueryData<FavoriteList>(['entries', 'favorites'], (old) =>
        old ? { ...old, entries: old.entries.filter((favorite) => favorite.id !== id) } : undefined
      );

      return { previousFavorites };
    },
    onError: (err: AxiosError, _id, context) => {
      // Favorites API returns a 404 error if item to be deleted is not in the list.
      // This is not an error: the item is not in the list, which is expected behaviour.
      // Do not roll back optimistic update in this case.
      if (err.response?.status !== 404) {
        queryClient.setQueryData(['entries', 'favorites'], context?.previousFavorites);
      }
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['entries', 'favorites', 'list-page'] });
      void queryClient.invalidateQueries({ queryKey: ['entries', 'favorites'] });
      void queryClient.invalidateQueries({ queryKey: ['favoriteCount'] });
    },
  });

  const hasFavorite = (id: number) => (favorites.data?.entries ?? []).find((f) => f.id === id) !== undefined;
  const hasSmsNotification = (id: number) => favorites.data?.entriesWithNotifications.has(id);

  const toggleSmsNotification = useMutation({
    mutationFn: (entryId: number) =>
      apiClient.patch(`/api/favorites/${entryId}`, { notify: !hasSmsNotification(entryId) }),
    onMutate: async (entryId: number) => {
      // Optimistic update
      await queryClient.cancelQueries({ queryKey: ['entries', 'favorites'] });
      const previousFavorites = queryClient.getQueryData<FavoriteList>(['entries', 'favorites']);

      queryClient.setQueryData<FavoriteList>(['entries', 'favorites'], (old) => {
        if (!old) return old;

        const isNotified = old.entriesWithNotifications.has(entryId);
        const updatedNotifications = new Set(old.entriesWithNotifications);
        if (isNotified) {
          updatedNotifications.delete(entryId);
        } else {
          updatedNotifications.add(entryId);
        }

        return {
          ...old,
          entriesWithNotifications: updatedNotifications,
        };
      });

      return { previousFavorites };
    },
    onError: (error: AxiosError<SmsNotificationFailureAPIResponse>, _entryId, context) => {
      queryClient.setQueryData(['entries', 'favorites'], context?.previousFavorites);

      if (error.response?.data.message === 'Invalid phone number') {
        playToast('Ilmoitusten asettaminen epäonnistui', 'Tarkista puhelinnumero', { variant: 'danger' });
      } else {
        playToast('Ilmoitusten asettaminen epäonnistui', 'Tuntematon virhe', { variant: 'danger' });
      }
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['entries', 'favorites'] });
    },
  });

  const toggleFavorite = (id: number) => {
    if (hasFavorite(id)) {
      return removeFavorite.mutate(id);
    }
    return addFavorite.mutate(id);
  };

  return {
    favorites,
    addFavorite,
    removeFavorite,
    hasFavorite,
    hasSmsNotification,
    toggleFavorite,
    toggleSmsNotification,
  };
};
